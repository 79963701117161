import React from "react";

const Users = () => {
return (
	<div>
	<h1>Jugasankha Users</h1>
	</div>
);
};

export default Users;
