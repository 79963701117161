import React from "react";
import { Routes, Router, Route } from "react-router-dom";
import Sidebar from "./Sidebar"; // Update the import path based on your actual Sidebar component location
import Upload from "./Upload";
import { Services } from "./Services";
import Users from "./Users";
import AdminLogin from "./AdminLogin";
import AdminDashboard from "./AdminDashboard";
import UploadSection from "./UploadSection";
import UploadedNewsSection from "./UploadedNewsSection";
import ListOfNews from "./ListOfNews";
import CategoryManagement from "../molecules/categoryManagement/CategoryManagement";
import EditUploadedNewsContent from "../molecules/editUploadedNewsContent/EditUploadedNewsContent";


function AdminRoute() {
  return (
    <>
      <Sidebar />
      <Routes>
        {/* <Route path="/admin/upload" element={<Upload />} /> */}
        <Route path="/admin/services" element={<Services />} />
        <Route path="/admin/users" element={<Users />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
        <Route path="/admin/uploadSection" element={<UploadSection />} />
        <Route path="/admin/uploadSection/uploadedNewsSection/:id/:ind" element={<UploadedNewsSection />} />
        <Route path="/admin/listOfNews" element={<ListOfNews />} />
        <Route path="/admin/addCategory" element={<CategoryManagement />} />
        <Route path="/admin/listOfNews/editUploadedNewsContent/:ind" element={<EditUploadedNewsContent />} />
      </Routes>
    </>
  );
}

export default AdminRoute;
