import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!sessionStorage.getItem("token")) {
        alert("Please Login")
      return navigate("/");
    } else {
      return navigate("/epaper");
    }
  }, []);

  return children;
};

export default PrivateRoute;
