import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector} from 'react-redux'
import { register } from '../redux/auth/authSlice'
import { ValidateReg } from '../validation/Validation'
import '../css/Register.css'
const init={
    full_name:'',
    mobile:'',
    email:'',
    confirmEmail:'',
    password:'',
    confirmPassword:'',
    address:'',
    cityState:'',
    pin:'',
    plan_id:1,
    autoRenewal:false
}
function Register({onNext}){
    const [data , setData]= useState(init)
    const [valError, setValError] = useState({})
    const [isSubmit, setIsSubmit] = useState(false)
    const dispatch = useDispatch()
    const authState = useSelector(state=>state.auth)
    console.log(authState, "authState")
    const { isError, message } = authState;
  
    const handleChange =(e) => {
       const {name,value} = e.target
        setData({...data,[name]:value})
    }

    const handleSubmit = (e) => {
       e.preventDefault()
        setValError(ValidateReg(data))
        setIsSubmit(true)
    }
     
    useEffect(() => {
      const handleRegistration = async () => {
        if (Object.keys(valError).length === 0 && isSubmit) {
          try {
            // Dispatch the 'register' action and wait for it to complete
            await dispatch(register(data));
            // 'authState' should contain the latest state after dispatch
            console.log(authState, "message after dispatch");
            onNext()
          } catch (error) {
            console.log("Error:", error);
          }
        }
      };
       
      // Call the handleRegistration function
      handleRegistration();
    }, [valError]);
    
    
    
    
return (
    <div className='registerCont'>
    <form className='registerForm' onSubmit={handleSubmit}>
        <label>
         Name
         <input name='full_name' type='text' onChange={handleChange}/>
        </label>
      <p>{valError.full_name}</p>

        <label>Mobile Number
        <input name='mobile' type='text' onChange={handleChange} />
        </label>
       <p>{valError.mobile}</p>
        <label>Email
        <input name='email' type='text' onChange={handleChange} />
        </label>
        <p>{valError.email}</p>

        <label>Confirm Email
        <input name='confirmEmail' type='text' onChange={handleChange} />
        </label>
       
        <p>{valError.confirmEmail}</p>

        <label>Address
        <input name='address' type='text' onChange={handleChange} />
        </label>
        <p>{valError.address}</p>

        <label>City/State
        <input name='cityState' type='text' onChange={handleChange} />
        </label>
        <p>{valError.cityState}</p>

        <label>PIN
        <input name='pin' type='text' onChange={handleChange} />
        </label>
        <p>{valError.pin}</p>

        <label for="plans">Select a Plan
        <select onChange={handleChange} name='plan_id'>
        <option value="1">1 month- 150</option>
        <option value="2">3 months</option>
        <option value="3">6 Months</option>
        <option value="4">12 Months</option>
       </select>
        </label>
       <p>{valError.plans}</p>

        <label>password
        <input name='password' type='text' onChange={handleChange} /> 
        </label>
        <p>{valError.password}</p>

        <label> Confirm Password
        <input name='confirmPassword' type='text' onChange={handleChange} /> 
        </label>
        <p>{valError.confirmPassword}</p>

        <input type='submit'/>
    </form>
    </div>
)
}

export default Register