import React, { useState } from "react";
import { orderStatusData } from "../dataApi/Admin/orderStatus.js";
import "../css/AdminDashboard.css";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  LineChart,
  AreaChart,
  Area,
} from "recharts";
import Map from "../molecules/map/Map.jsx";
import { customers } from "../dataApi/Admin/customers.js";
import ApexCharts from "../molecules/apexChart/ApexChart.jsx";
import ProgressBar from "../molecules/progressBar/ProgressBar.jsx";

const data = [
  { p: 25 },
  { p: 66 },
  { p: 41 },
  { p: 59 },
  { p: 25 },
  { p: 44 },
  { p: 12 },
  { p: 36 },
  { p: 9 },
  { p: 21 },
];

// const testData = [{ bgcolor: "#6a1b9a", completed: 60 }];

const AdminDashboard = () => {
  return (
    <>
      <div id="dashboardContainer">
        <div id="greetingBoxSection-1">
          <div className="greetingBoxLeftContent">
            <h4>Hi, Welcome Back!</h4>
            <p>Lorem ipsum dolor sit amet consectetur </p>
          </div>
          <div className="greetingBoxRightContent">
            <div className="Ratings">
              <p>Customer Rating</p>
              <p>***** (14,873)</p>
            </div>
            <div>
              <p>Online Sales</p>
              <h5>563,275</h5>
            </div>
            <div>
              <p>Offline Sales</p>
              <h5>783,675</h5>
            </div>
          </div>
        </div>
        <div id="businessDetailSection-2">
          <div className="businessDetailSectionCards">
            <div className="businessDetailSectionCardsContent">
              <div>
                <p>TODAY ORDERS</p>
                <h5>$574.12</h5>
                <p>Compared to last week</p>
              </div>
              <div>
                <p>^</p>
                <p>+427</p>
              </div>
            </div>
            <div className="businessDetailSectionCardsAreaChart">
              <ResponsiveContainer width="100%" height={40}>
                <AreaChart
                  width={500}
                  // height={400}
                  data={data}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <Area dataKey="p" stroke="white" fill="none" />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="businessDetailSectionCards">
            <div
              className="businessDetailSectionCardsContent"
              style={{
                backgroundImage: "linear-gradient(45deg, #f93a5a, #f7778c)",
              }}
            >
              <div>
                <p>TODAY ORDERS</p>
                <h5>$574.12</h5>
                <p>Compared to last week</p>
              </div>
              <div>
                <p>^</p>
                <p>+427</p>
              </div>
            </div>
            <div
              className="businessDetailSectionCardsAreaChart"
              style={{
                backgroundImage: "linear-gradient(45deg, #f93a5a, #f7778c)",
              }}
            >
              <ResponsiveContainer width="100%" height={40}>
                <AreaChart
                  width={500}
                  // height={400}
                  data={data}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <Area dataKey="p" stroke="white" fill="none" />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="businessDetailSectionCards">
            <div
              className="businessDetailSectionCardsContent"
              style={{
                backgroundImage:
                  "linear-gradient(to left, #48d6a8 0%, #029666 100%)",
              }}
            >
              <div>
                <p>TODAY ORDERS</p>
                <h5>$574.12</h5>
                <p>Compared to last week</p>
              </div>
              <div>
                <p>^</p>
                <p>+427</p>
              </div>
            </div>
            <div
              className="businessDetailSectionCardsAreaChart"
              style={{
                backgroundImage:
                  "linear-gradient(to left, #48d6a8 0%, #029666 100%)",
              }}
            >
              <ResponsiveContainer width="100%" height={40}>
                <AreaChart
                  width={500}
                  // height={400}
                  data={data}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <Area dataKey="p" stroke="white" fill="none" />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="businessDetailSectionCards">
            <div
              className="businessDetailSectionCardsContent"
              style={{
                backgroundImage: "linear-gradient(to left, #efa65f, #f76a2d)",
              }}
            >
              <div>
                <p>TODAY ORDERS</p>
                <h5>$574.12</h5>
                <p>Compared to last week</p>
              </div>
              <div>
                <p>^</p>
                <p>+427</p>
              </div>
            </div>
            <div
              className="businessDetailSectionCardsAreaChart"
              style={{
                backgroundImage: "linear-gradient(to left, #efa65f, #f76a2d)",
              }}
            >
              <ResponsiveContainer width="100%" height={40}>
                <AreaChart
                  width={500}
                  // height={400}
                  data={data}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <Area dataKey="p" stroke="white" fill="none" />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        <div id="order-and-sale-section-3">
          <div className="order-status">
            <h6>ORDER STATUS</h6>
            <p>
              Order Status and Tracking. Track your order from ship date to
              arrival. To begin, enter your order number.
            </p>

            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                width={500}
                height={300}
                data={orderStatusData} // Wrap the current object in an array
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid vertical={false} />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend
                  verticalAlign="top"
                  align="center"
                  height={36}
                  iconType="circle"
                  iconSize={8}
                />
                <Bar dataKey="pv" fill="#8884d8" barSize={12} />
                <Bar dataKey="uv" fill="#82ca9d" barSize={12} />
                <Bar dataKey="amt" fill="red" barSize={12} />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="sales-revenue">
            <h6>Sales Revenue by Customers in USA</h6>
            <p>Sales Performance of all states in the United States</p>
            <Map />
          </div>
        </div>
        <div id="main-box-section-4">
          <div className="cards-section-4">
            <h6>RECENT CUSTOMERS</h6>
            <p>
              A customer is an individual or business that purchases the goods
              service has evolved to include real-time
            </p>
            {customers.map((el, ind) => (
              <div className="customer-details">
                <div>
                  <div>
                    <img
                      src={el.profilePicture}
                      alt="Image Description"
                      width={"100%"}
                      // height={"40px"}
                    />
                  </div>
                  <div>
                    <h6>{el.name}</h6>
                    <p>
                      USER ID: <span>{el.userId}</span>{" "}
                      <span
                        style={{
                          marginLeft: "1rem",
                          color: el.status === "Paid" ? "green" : "red",
                        }}
                      >
                        {el.status}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="lineChartBox">
                  <ResponsiveContainer width="100%" height={50}>
                    <LineChart data={data}>
                      <Line dataKey={"p"} stroke="blue" activeDot={{ r: 8 }} />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
            ))}
          </div>
          <div className="apex-pie-chart-section">
            <div>
              <ApexCharts />
            </div>
            <div>
              <div>
                <p style={{ color: "gray", fontSize: "18px" }}>Total Sales</p>
                <h3>$ 7590</h3>
                {/* {testData.map((item, idx) => (
                    <ProgressBar
                      key={idx}
                      bgcolor={item.bgcolor}
                      completed={item.completed}
                    />
                  ))} */}
              </div>
              <div>
                <p style={{ color: "gray", fontSize: "18px" }}>Active Users</p>
                <h3>$ 5460</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
