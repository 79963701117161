import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminLogin from "../admin/AdminLogin";
import NavbarLink from "./NavbarLink";
import AdminRoute from "../admin/AdminRoute"; // Import the AdminRoute component
import ContactUs from "../app/ContactUs";
import { useSelector } from "react-redux";
import Footer from "../app/Footer";
import About from "../app/About";
import Dashboard from "../app/Dashboard";
import NewsDetails from "../app/NewsDetails";
import PrivateRoute from "./PrivateRoute";
import SingleNews from "../app/Singlenews";

function AppLink() {
  const authState = useSelector((state) => state.auth);
  const { isSuccess } = authState;

  return (
    <>
      <div>
        <NavbarLink />
        <Routes>
          <Route path="/admin" element={<AdminLogin />} />
          <Route
            path="/epaper"
            element={
              <PrivateRoute>
                <NewsDetails />
              </PrivateRoute>
            }
          />
          <Route path="/about" element={<About />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/single-news" element={<SingleNews />} />
        </Routes>
        <Footer />
      </div>
    </>
  );
}

export default AppLink;
