import React, { useState } from "react";
import Modal from "../../atoms/modal/Modal";

const EditCategoryManagement = ({ onValueChange }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    onValueChange(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    onValueChange(false);
  };
  return (
    <>
      <Modal isOpen={openModal} onClose={closeModal}>
        {/* Modal content */}
        <h2>Edit News</h2>
        {/* Add the edit form or content here */}
        {/* Example: */}
        <form>
          {/* Edit form fields */}
          <button type="submit">Save</button>
        </form>
      </Modal>
    </>
  );
};

export default EditCategoryManagement;
