import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const loginSlice = createSlice({
  name: "login",
  initialState: {
    data: [],
    isSuccess: false,
    isError: false,
    message: "",
    token: "",
  },
  reducers: {
    loginRequest: (state) => {
      state.isSuccess = true;
      state.isError = false;
    },
    loginSuccess: (state, action) => {
      state.isSuccess = true;
      state.isError = false;
      state.message = action.payload;
      state.token = action.payload.data.token;
      // console.log(state.message, 'message')
      // console.log(state.token, 'token')
    },
    loginFailure: (state, action) => {
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload;
    },
  },
});

export const { loginRequest, loginSuccess, loginFailure } = loginSlice.actions;
export default loginSlice.reducer;

export const login = (payload) => async (dispatch) => {
  // console.log(payload,"pppp")
  dispatch(loginRequest());

  try {
    const response = await axios.post(
      "https://jugasankha.travwiki.com/apis/auth?type=login",
      payload
    );
    console.log(response.data);
    dispatch(loginSuccess(response.data));
    sessionStorage.setItem("token", response.data.success);
    localStorage.setItem("axis_token", response.data.data.token)
  } catch (error) {
    dispatch(loginFailure(error));
  }
};
