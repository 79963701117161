import { configureStore } from '@reduxjs/toolkit';
import authSlice from './auth/authSlice';
import loginSlice from './auth/loginSlice';
import uploadSlice from './admin/uploadSlice';
import categoryManagementSlice from './admin/categoryManagementSlice';


export default configureStore({
  reducer: {
    auth: authSlice,
    login: loginSlice,
    upload: uploadSlice,
    categoryManagement: categoryManagementSlice, 
  },
});



// import { compose, createStore, applyMiddleware, combineReducers } from "redux";
// import thunk from "redux-thunk";
// import { authReducer } from "./auth/reducer";

// const rootReducer = combineReducers({
//   auth: authReducer
// });

// export const store = createStore(
//   rootReducer,
//   compose(
//     applyMiddleware(thunk),
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//   )
// );