import React from "react";
import ReactApexChart from "react-apexcharts";

function ApexCharts() {
  const options = {
    chart: {
      type: "donut",
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    labels: ["Delivery", "Canceled"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    legend: {
      position: "bottom",
      markers: {
        shape: "square", // Set legend icons to squares
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
              label: "Total",
              fontSize: "22px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#373d3f",
              formatter: function (w) {
                return (
                  w.globals.seriesTotals
                    .reduce((a, b) => {
                      return a + b;
                    }, 0)
                    .toFixed(2) + "%" // Display as a percentage with 2 decimal places
                );
              },
            },
          },
        },
      },
    },
    colors: ["#0162e8", "rgba(1, 98, 232, 0.5)"], // Set colors for Delivery and Canceled
    responsive: [
      {
        breakpoint: 768,
        options: {
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const series = [65, 35]; // Delivery and Canceled percentages

  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        width="100%"
        height="300"
      />
    </div>
  );
}

export default ApexCharts;
