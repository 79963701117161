import React from "react";
import MultiCrops from "react-multi-crops";
import "../css/mapArea.css";
import axios from "axios";

class ImageCropper extends React.Component {
  state = {
    coordinates: [],
    apiData: [],
  };

  componentDidMount() {

    const authToken = localStorage.getItem("axis_token");
    // Check if the token is present
    if (!authToken) {
      throw new Error("Authorization token is missing or invalid.");
    }

    // Call your API with the Authorization header
    fetch(process.env.REACT_APP_APIURL + `/news?attachments=true&id=${this.props.alt}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })
        .then((response) => response.json())
        .then((res) => {
          if (res.status) {
            this.setState({apiData: res.data});
            localStorage.setItem('mapData', res.data.maped_data)
            this.addCoordinate();
          }
        })
        .catch((error) => {
          console.error("Error fetching data from API: ", error);
        });
  }

  changeCoordinate = (coordinate, index, coordinates) => {
    this.setState({
      coordinates,
    });
  };
  addCoordinate = () => {
    const initialCoordinates = [];
    let mapData = localStorage.getItem("mapData");
    mapData = mapData ? JSON.parse(mapData) : [];
    mapData.forEach((imageData) => {
      const imageKey = this.props.alt; // Use the alt prop directly as the key
      const coordsArray = imageData[imageKey]; // Get the array of coordinates

      // Add coordinates to the initialCoordinates array
      if (coordsArray && Array.isArray(coordsArray)) {
        initialCoordinates.push(
          ...coordsArray.map((coordObject) => coordObject.coord)
        );
      }
    });

    this.setState({
      coordinates: initialCoordinates,
    });
  };

  generateCroppedImages = () => {
    const { coordinates } = this.state;
    const imgElement = document.createElement('img');
    imgElement.src = this.props.src;

    const canvas = document.createElement('canvas');
    canvas.width = imgElement.width;
    canvas.height = imgElement.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(imgElement, 0, 0);

    return coordinates.map((coord, index) => {
      const croppedCanvas = document.createElement('canvas');
      const width = coord.width;
      const height = coord.height;
      croppedCanvas.width = width;
      croppedCanvas.height = height;

      const croppedCtx = croppedCanvas.getContext('2d');
      croppedCtx.drawImage(
          canvas,
          coord.x,
          coord.y,
          width,
          height,
          0,
          0,
          width,
          height
      );

      // Convert canvas to a data URL
      const dataURL = croppedCanvas.toDataURL("image/png");

      return {
        index,
        dataURL,
        coord
      };
    });
  }
  deleteCoordinate = (coordinate, index, coordinates) => {
    this.setState({
      coordinates,
    });
  };

  handleAreaClick = (index, croppedImages) => {
    this.setState({
      selectedArea: index,
      selectedImage: croppedImages[index].dataURL,
    });
  };

  handleSaveData = async (croppedImages) => {
    let mapData = localStorage.getItem("mapData");
    mapData = mapData ? JSON.parse(mapData) : [];
    let isExist = mapData.some((el) => el.hasOwnProperty(this.props.alt));
    if (!isExist) {
      mapData.push({ [`${this.props.alt}`]: croppedImages });
    } else {
      mapData = mapData.find((el) => {
        return el[this.props.alt];
      });
      mapData[this.props.alt] = croppedImages;
    }
    localStorage.setItem("mapData", JSON.stringify(mapData));

    // Get the token from localStorage
    const token = localStorage.getItem("axis_token");
    // Check if the token is present
    if (!token) {
      throw new Error("Authorization token is missing or invalid.");
    }

    const response = await axios.put(
        `${process.env.REACT_APP_APIURL}/news?id=${this.props.alt}`,
        {mappedData : JSON.stringify(mapData)},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
    );

    alert(response.data.message)
  };

  render() {
    const croppedImages = this.generateCroppedImages();
    return (
      <div className={"col-md-12"}>
        <button className="btn btn-primary pull-right" style={{ margin: "0.5rem 1rem 1rem 25rem" }} onClick={() => this.handleSaveData(croppedImages)}>
          Save Mapped Area
        </button>
        <button className="btn btn-primary pull-right" style={{ margin: "0.5rem 0 1rem 0" }} onClick={() => this.addCoordinate()}>
          Load Mapped Area
        </button>
        <div style={{boxShadow:"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", }}>
          <MultiCrops
            src={this.props.src}
            width={this.props.width}
            coordinates={this.state.coordinates}
            onChange={this.changeCoordinate}
            onDelete={this.deleteCoordinate}
            
          />
        </div>

        {/*<div>
                    <img src={this.state.selectedImage} alt=""/>
                </div>
                <div>
                    {this.state.coordinates.map((coord, index) => (
                        <div
                            key={index}
                            style={{
                                position: 'absolute',
                                left: coord.x+265,
                                top: coord.y+ 80,
                                width: coord.width,
                                height: coord.height,
                                border: this.state.selectedArea === index ? '2px solid red' : '2px solid blue',
                            }}
                            onClick={() => this.handleAreaClick(index,croppedImages)}
                        />
                    ))}
                </div>*/}
      </div>
    );
  }
}

export default ImageCropper;
