import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const categoryManagementSlice = createSlice({
  name: "categoryManagement",
  initialState: {
    data: [],
    isSuccess: false,
    isError: false,
    message: "",
  },
  reducers: {
    categoryRequest: (state) => {
      state.isSuccess = true;
      state.isError = false;
    },
    categorySuccess: (state, action) => {
      state.isSuccess = true;
      state.isError = false;
      state.data = action.payload;
      console.log(state.message, "message");
    },
    categoryFailure: (state, action) => {
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload;
    },
  },
});

export const { categoryRequest, categorySuccess, categoryFailure } =
  categoryManagementSlice.actions;
export default categoryManagementSlice.reducer;

export const postCategory = (categoryData) => async (dispatch, getState) => {
  dispatch(categoryRequest());
  // console.log(categoryData)
  try {
    // name, parent_id, status  -----> backend structure

    // Get the token from localStorage
    const token = localStorage.getItem("axis_token");
    // Check if the token is present
    if (!token) {
      throw new Error("Authorization token is missing or invalid.");
    }
    const response = await axios.post(
      `${process.env.REACT_APP_APIURL}/categories`,
      categoryData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    // console.log(response.data.data);
    dispatch(categorySuccess(response.data.data));
  } catch (error) {
    // Handle errors, log them, or dispatch failure action
    console.error("Error:", error);
    dispatch(categoryFailure(error.massage));
  }
};


export const getCategory = () => async (dispatch, getState) => {
  dispatch(categoryRequest());

  try {
    const token = localStorage.getItem("axis_token");
    if (!token) {
      throw new Error("Authorization token is missing or invalid.");
    }
    const categoryData = await axios.get(`${process.env.REACT_APP_APIURL}/categories`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    //   console.log(categoryData.data.data);
      dispatch(categorySuccess(categoryData.data.data));
  } catch (error) {
    console.error("Error:", error);
    dispatch(categoryFailure(error));
  }
};


export const deleteCategory = (id) => async (dispatch, getState) => {
  dispatch(categoryRequest());
  try {
    const token = localStorage.getItem("axis_token");
    if (!token) {
      throw new Error("Authorization token is missing or invalid.");
    }
    const res = await axios.delete(
      `${process.env.REACT_APP_APIURL}/categories?id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    console.log(res);
  } catch (error) {
    console.error("Error:", error);
    dispatch(categoryFailure(error));
  }
};
