import React, { useEffect, useState } from "react";
import "../css/ListOfNews.css";
import { useDispatch, useSelector } from "react-redux";
import { deleteNews, getUploadedNews } from "../redux/admin/uploadSlice";
import { Link, useNavigate } from "react-router-dom";
import EditModalOfNewsLists from "../molecules/editUploadedNewsContent/EditUploadedNewsContent";

const ListOfNews = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortedNews, setSortedNews] = useState([]);
  const [sortDirection, setSortDirection] = useState("asc");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: allNews } = useSelector((state) => state.upload);
  // console.log(allNews);

  //function to get all news
  const getNews = async () => {
    try {
      await dispatch(getUploadedNews());
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getNews();
    return () => {
      console.log("component end");
    };
  }, []);

  // Function to handle sorting by date
  const handleSortByDate = () => {
    const sorted = [...allNews].sort((a, b) => {
      if (sortDirection === "asc") {
        return new Date(a.post_date) - new Date(b.post_date);
      } else {
        return new Date(b.post_date) - new Date(a.post_date);
      }
    });
    setSortedNews(sorted);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  // Function to handle search
  const handleSearch = () => {
    const filteredNews = allNews.filter((el) =>
      el.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setSortedNews(filteredNews);
  };

  //function to handle delete
  const handleDelete = async (id) => {
    await dispatch(deleteNews(id));
    getNews();
  };

  return (
    <>
      <div className="table-main-container">
        <div className="header-box">
          <h4 className="table-heading">Uploaded List of News</h4>
          <div className="search-container">
            <input
              type="text"
              placeholder="Search by title"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button onClick={handleSearch}>Search</button>
          </div>
        </div>
        <table className="table-box">
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Description</th>
              <th>Category ID</th>
              <th>Status</th>
              <th>Pages</th>
              <th onClick={handleSortByDate} style={{ cursor: "pointer" }}>
                Post Date{" "}
                {sortDirection === "asc" ? (
                  <span>&uarr;</span>
                ) : (
                  <span>&darr;</span>
                )}
              </th>
              <th style={{ textAlign: "center" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {allNews && allNews.length > 0 ? (
              allNews.map((el, ind) => (
                <tr key={el.id}>
                  <td>{el.id}</td>
                  <td>{el.title}</td>
                  <td>{el.description}</td>
                  <td>{el.category_id}</td>
                  <td>{el.status}</td>
                  <td>
                    {el.attachment && el.attachment.length > 0
                      ? `${el.attachment.length} Pages Attached`
                      : `No attachments`}
                  </td>
                  <td>{el.post_date}</td>
                  <td className="actions">
                    <Link
                      to={`/admin/uploadSection/uploadedNewsSection/${el.id}/${ind}`}
                    >
                      <button className="actions-btn">View</button>
                    </Link>
                    <Link
                      to={`/admin/listOfNews/editUploadedNewsContent/${ind}`}
                    >
                      <button className="actions-btn">Edit</button>
                    </Link>
                    <button
                      className="actions-btn"
                      onClick={() => handleDelete(el.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9">Loading...</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ListOfNews;
