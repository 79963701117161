import React from 'react'

function About (){
    console.log("about")
    return (
        <div>About
        </div>
    )
}

export default About