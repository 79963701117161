import React from "react";
import { Link } from "react-router-dom";

import { FaBars, FaTimes } from "react-icons/fa";
import "../css/Navbar.css";
import Logo from "../app/utils/logo.png";
function Navbar() {
  const [click, setClick] = React.useState(false);

  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);

  const authentication = sessionStorage.getItem("token");

  const handleLogout = () => {
    sessionStorage.removeItem("token")
  }

  return (
    <div>
      <div className={click ? "mainContainer" : ""} onClick={() => Close()} />
      <nav className="navbar" onClick={(e) => e.stopPropagation()}>
        <div className="navContainer">
          <Link exact to="/" className="navLogo">
            <img src={Logo} alt="Jugasankha logo" />
          </Link>
          <div className="navMenu">
            {authentication ? null : <Link className="navItem" to="/register">
              <button className="register">REGISTER</button>
            </Link>}
            <Link className="navItem" to="/renew">
              <button className="renew">RENEW</button>
            </Link>
            {authentication ? (
              <Link className="navItem" to="/">
                <button onClick={handleLogout} className="renew">LOGOUT</button>
              </Link>
            ) : null}
          </div>
        </div>
      </nav>
    </div>
  );
}
export default Navbar;
