import React, { useEffect, useState } from "react";
import "./CategoryManagement.css";
import {
  deleteCategory,
  getCategory,
  postCategory,
} from "../../redux/admin/categoryManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import EditCategoryManagement from "../editCategoryManagment/EditCategoryManagement";

const CategoryManagement = () => {
  const [categoryName, setCategoryName] = useState("");
  const [parentCategory, setParentCategory] = useState("0");
  const [status, setStatus] = useState("Select Status");
  const [childValue, setChildValue] = useState("");
  const { data: allCategories } = useSelector(
    (state) => state.categoryManagement
  );
  const dispatch = useDispatch();
  // console.log(allCategories)

  const getAllCategories = async () => {
    try {
      await dispatch(getCategory());
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    getAllCategories();
    return () => {
      console.log("component end");
    };
  }, []);

  //function for send data to backend(handle Category Management)
  const handleCategoryManagement = async (e) => {
    e.preventDefault();

    const formDataOfCategory = {
      name: categoryName,
      parent_id: parentCategory,
      status: status,
    };
    // console.log(formDataOfCategory);

    try {
      await dispatch(postCategory(formDataOfCategory));
    } catch (error) {
      return console.log(error);
    }
    //clear the form fields
    setCategoryName("");
    setParentCategory("");
    setStatus("");
  };

  //function for handle Edit Data
  const handleEdit = (value, id) => {
    setChildValue(value);
  };

  //function for handle delete data
  const handleDelete = async (id) => {
    await dispatch(deleteCategory(id));
    getAllCategories();
  };

  return (
    <>
      <div className="categoryManagementContainer">
        <h1>Category Management Form</h1>
        <form
          className="categoryManagementForm"
          onSubmit={handleCategoryManagement}
        >
          <input
            type="text"
            placeholder="Enter category name"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />
          <select
            value={parentCategory}
            onChange={(e) => setParentCategory(e.target.value)}
          >
            <option value="">Select Parent Category</option>
            {allCategories.map((el, ind) => (
              <option key={ind} value={el.id}>
                {el.name}
              </option>
            ))}
          </select>
          <select value={status} onChange={(e) => setStatus(e.target.value)}>
            <option value="selectStatus">Select Status</option>
            <option value="active"> Active</option>
            <option value="inactive">Inactive</option>
          </select>
          <button id="category_submit-btn" type="submit">
            Submit
          </button>
        </form>
      </div>
      <div className="category-table-container">
        <table className="table-box">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>SR. No.</th>
              <th>ID</th>
              <th>Category Name</th>
              <th>Parent_ID</th>
              <th>Status</th>
              <th style={{ textAlign: "center" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {allCategories && allCategories.length > 0 ? (
              allCategories.map((el, ind) => (
                <tr key={el.id}>
                  <td style={{ textAlign: "center" }}>{ind + 1}</td>
                  <td>{el.id}</td>
                  <td>{el.name}</td>
                  <td>{el.parent_id}</td>
                  <td>{el.status}</td>
                  <td className="category-actions">
                    <button
                      className="category-actions-btn"
                      onClick={() => handleEdit(el.id)}
                    >
                      Edit
                    </button>
                    <button
                      className="category-actions-btn"
                      onClick={() => handleDelete(el.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9">Loading...</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {childValue ? <EditCategoryManagement onValueChange={handleEdit}/> : null}
    </>
  );
};

export default CategoryManagement;
