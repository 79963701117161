import React, { useState, useEffect } from "react";
import { FaRegNewspaper } from "react-icons/fa";
import "./Calendar.css";
import axios from "axios";

const Calendar = () => {
  const [selectedDateNews, setSelectedDateNews] = useState([]);
  const [dateList, setDateList] = useState([]);
  const [newsList, setNewsList] = useState([]);

  useEffect(() => {

      // Get the token from localStorage
      const token = localStorage.getItem("axis_token");
      // Check if the token is present
      if (!token) {
        throw new Error("Authorization token is missing or invalid.");
      }
      const response = axios.get(
          `${process.env.REACT_APP_APIURL}/news?date_wise=true`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
      ).then((response) => {
        setDateList(Object.keys(response.data.data));
        setNewsList(response.data.data);
      }).catch(e => console.error(e.message));
  }, []);

  // Function to handle date selection
  const handleDateSelect = (dateIndex) => {
    console.log('dateIndex',dateIndex,newsList[dateIndex])
    setSelectedDateNews(newsList[dateIndex]);
    // Yahan par aap selected date ke hisab se news data ko fetch kar sakte hain.
  };

  return (
    <>
      <div id="calendar-container">
        <div className="latest-news-heading">
          <FaRegNewspaper id="icon" />
          <h5 style={{ marginTop: "8px" }}>Latest News</h5>
        </div>
        <div className="calendar">
          {dateList.map((date, index) => (
            <button key={index} onClick={() => handleDateSelect(date)}>
              {date}
            </button>
          ))}
        </div>
        {selectedDateNews.map((news,index) => (
          <div key={index}>
            <h3>News for {news.title}</h3>
            {/* Yahan par selected date ke hisab se news data ko render kar sakte hain */}
          </div>
        ))}
      </div>
    </>
  );
};

export default Calendar;
