import React from "react";
import { useLocation } from "react-router-dom"; // Import useLocation to access the prop

function SingleNews() {
  const location = useLocation();
  const newsUrl = location.state?.newsUrl || ""; // Retrieve the newsUrl prop

  return (
    <div className="single-news-container">
      <h1>Single News Page</h1>
      <div>
        <p>URL: {newsUrl}</p>
        {/* Render other data here */}
      </div>
    </div>
  );
}

export default SingleNews;
