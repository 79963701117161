export const ValidateReg =(val) => {
    const errors ={};
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[a-zA-Z\d@$!%*?&#]{8,}$/;
;
    if(!val.full_name){
        errors.full_name ="Email required!"
    }
     if(!val.mobile){
      errors.mobile = 'Mobile number required!'
    }
     if (!val.email){
        errors.email ='Email Id required!'
    }
     if (!val.password){
        errors.password = 'Password required!'
    }
    if(!val.confirmPassword){
        errors.confirmPassword ='Enter Confirm Password'
    }
     if (!val.plan_id){
        errors.plan_id = 'Select a Plan'
    }
    else if(!val.email.match(emailRegex)){
      errors.email = "Enter a Valid email address"
    }
    else if(!val.password.match(passwordRegex)){
        errors.password = "Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, and one number"
    }
    if(val.password !== val.confirmPassword){
        errors.confirmPassword = "Password do not match"
    }
    return errors
}

export const ValidateLogin =(val)=> {
    const errors ={};
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    if(!val.email){
        errors.email ='Enter your Email/Mobile number'
    }
    if(!val.password){
        errors.password ='Enter your Password'
    }
    return errors
}