import React from "react";
import { BsFillBookmarkFill } from "react-icons/bs";
import "./Bookmark.css";
import Paper from "../../../mapImages/paper.jpg";

const Bookmark = () => {
  return (
    <>
      <div id="bookmark-container">
        <div className="bookmark-heading">
          <BsFillBookmarkFill id="icon" />
          <h5 style={{ marginTop: "5px" }}>Bookmark</h5>
        </div>
        <div id="bookmarked-data">
          <div>
            <img src={Paper} alt="Paper" width={"100%"} height={"100%"} />
          </div>
        </div>
        <div id="view-button">
          <button>View All</button>
        </div>
      </div>
    </>
  );
};

export default Bookmark;
