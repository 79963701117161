import React from 'react'

import SubPlans from './SubPlans'
import Login from '../auth/Login'
import Editions from './Editions'
import '../css/HomePage.css'
import { Link } from 'react-router-dom'
import Features from './Features'
// import CarouselComp from './CarouselComp'
import Advertise from '../app/utils/advertise.png'
function HomePage ()  {
   return (
     <div>
      {/* <CarouselComp/> */}
      <div className='firstCont'>
        <Editions/>
        <Login/>
      </div>
       <SubPlans/>
       <div className='advertise'>
       <img src={Advertise} alt='advertise' />
       </div>
       <div className='description'>
        <h2>What we provide?</h2>
        <p>This web portal is the largest circulated Bengali daily in the entire Northeast, with two widely circulated editions in Silchar and Guwahati, including West Bengal’s -- Kolkata, Siliguri and Durgapur. It delivers the most reliable, authentic and unbiased news to its dedicated readership in Bengali. It provides more diverse multimedia content than other linguistic sites which gives everyday special and detailed content.</p>
       </div>
       <Features/>

     </div>
   )
}

export default HomePage