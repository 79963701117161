import React from 'react';
import '../css/Features.css'; 
import { FaShare, FaBookmark, FaNewspaper } from 'react-icons/fa';

const Features = () => {
  return (
    <div className='mainFeature'>
    <h2 clasName='sectionTitle'>Features</h2>
    <div className="featureContainer">
      <div className="featureItem">
        <FaShare className="featureIcon" />
        <h3 className="feature-title">Share</h3>
        <p className="feature-description">Share articles with your friends and colleagues.</p>
      </div>
      <div className="featureItem">
        <FaBookmark className="featureIcon" />
        <h3 className="feature-title">Bookmark</h3>
        <p className="feature-description">Bookmark your favorite articles for easy access.</p>
      </div>
      <div className="featureItem">
        <FaNewspaper className="featureIcon" />
        <h3 className="feature-title">Different Editions</h3>
        <p className="feature-description">Access different editions of the ePaper.</p>
      </div>
    </div>
</div>
  );
};

export default Features;
