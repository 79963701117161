import React, { useState } from "react";
import "../css/Login.css";
import { ValidateLogin } from "../validation/Validation";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/auth/loginSlice";
import { useNavigate } from "react-router-dom";

function Login() {
  const init = {
    email: "",
    password: "",
  };

  const [data, setData] = useState(init);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginState = useSelector((state) => state.login);
  const { token } = loginState;
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log(data);
    setErrors(ValidateLogin(data));
    if (Object.keys(errors).length === 0) {
      await dispatch(login(data));
    }
    if (sessionStorage.getItem("token")) {
      return navigate("/epaper");
    }
    console.log(token);
  };




  return (
    <div class="loginCont">
      <form onSubmit={handleLogin} class="loginForm">
        <h1>Login</h1>
        <div class="loginRow">
          <label for="email">Email</label>
          <input
            type="email"
            name="email"
            autocomplete="off"
            placeholder="email@example.com"
            onChange={handleChange}
          />
        </div>
        <p>{errors.email}</p>
        <div class="loginRow">
          <label for="password">Password</label>
          <input type="password" name="password" onChange={handleChange} />
        </div>
        <p>{errors.password}</p>
        <button class="loginButton" type="submit">
          Login
        </button>

        <p>Forgot Password?</p>
      </form>
    </div>
  );
}

export default Login;
