import Footer from "./components/app/Footer";
import AppLink from "./components/routes/AppLink";
import NavbarLink from "./components/routes/NavbarLink";
import './App.css'
import AdminRoute from "./components/admin/AdminRoute";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";



function App() {
  const [toggle, setToggle]= useState(false)
  const location = useLocation()
  // console.log(location.pathname)
  
  const isAdmin =()=> {
   if( location.pathname.includes('/admin')) {
    setToggle(true)
   }
   else{
    setToggle(false)
   }
  }
  
  useEffect(()=> {
    isAdmin()
 },[location.pathname])
  return (
    <div id='app'>
      {toggle?<AdminRoute/>:<AppLink/>}
        {/* <AppLink/> */}
        {/* <AdminRoute/>   */}
    </div>
  );
}

export default App;
