import { useEffect, useState } from "react";
import MultiCrops from "react-multi-crops";
import axios from "axios";
import Calendar from "../molecules/calendar/Calendar";
import Bookmark from "../molecules/Bookmark/Bookmark";
import "../css/NewsDetails.css";
import { useNavigate } from "react-router-dom";

let isMobile = window.innerWidth;

function NewsDetails() {
  const navigate = useNavigate();
  const [selectedImages, setSelectedImages] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [coords, setCoords] = useState([]);
  const [modalImg, setModalImg] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);

 
  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 0.1);
  };

  const handleZoomOut = () => {
    if (zoomLevel > 0.1) {
      setZoomLevel(zoomLevel - 0.1);
    }
  };


  const handleResize = () => {
    isMobile = window.innerWidth;
    console.log(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleAreaClick = (index) => {
    setModalImg(coords[index].dataURL);
  };

  const handleNextPage = (index) => {
    const newsUrl = coords[index].urls;
    navigate(`/single-news?url=${encodeURIComponent(newsUrl)}`, {
      state: { newsUrl },
    });
  };

  const handleBlueAreaClick = (index) => {
    if (isMobile <= 600) {
      handleNextPage(index);
    } else {
      handleAreaClick(index);
    }
  };


  const loadNews = async () => {
    const url = "https://janshanka.travwiki.in/apis/news?id=3";
    const headers = {
      Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7ImlkIjoiMSIsImZ1bGxfbmFtZSI6IkpvaG4gRG9lIiwiZW1haWwiOiJqb2huLmRvZUBleGFtcGxlLmNvbSIsIm1vYmlsZSI6IjEyMzQ1Njc4OTAiLCJyb2xlX2lkIjoiMSJ9fQ.L6huEBCOopbZzQWDAetVPlhlyboiAYl17oo4jq9vlKs`,
      "Content-Type": "multipart/form-data",
    };
    try {
      const response = await axios.get(url, { headers });
      setNewsData(response.data.data);
      setSelectedImages(
        response.data.data.attachment
          ? response.data.data.attachment.split(",")
          : []
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    loadNews();
  }, []);

  
  const handleClick = (index) => {
    setSelectedImage(null);
    const image = {
      name: selectedImages[index].split("_CHNG_")[1],
      originalName: selectedImages[index],
    };
    setSelectedImage(image);
    const mapData = JSON.parse(newsData.maped_data);
    const singleImageCoords = mapData.find((el) => {
      return el[image.name];
    });
    setCoords(singleImageCoords[image.name]);
  };

  useEffect(() => {
    if (isMobile) {
      setIsModalVisible(false);
    } else {
      setIsModalVisible(true);
    }
  }, []);

  return (
    <div className="container-fluid">
      <h1 className="news-heading">News Details</h1>
      <div className="content-main-box">
        <div className="col-md-2" id="news">
          {selectedImages.map((image, index) => (
            <div style={{ border: "1px solid lightgray" }}>
              <img
                key={index}
                width="100%"
                src={"https://janshanka.travwiki.in/apis/uploads/news/" + image}
                alt={`Uploaded ${index + 1}`}
                onClick={() => handleClick(index)}
                style={{ cursor: "pointer" }}
              />
            </div>
          ))}
        </div>
        <div className="content-box-1">
          {selectedImage && (
            <div className="content-news">
              <h2>{newsData.title}</h2>
              <p>{newsData.description}</p>
              <img
                key={selectedImage.name}
                src={
                  "https://janshanka.travwiki.in/apis/uploads/news/" +
                  selectedImage.originalName
                }
                alt={selectedImage.name}
                className="multi-crops"
              />
              {coords.map((coord, index) => (
                <div
                  data-toggle="modal"
                  data-target="#exampleModal"
                  key={index}
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    left: coord.coord.x + 252,
                    top: coord.coord.y + 213,
                    width: coord.coord.width,
                    height: coord.coord.height,
                    border: "2px solid blue",
                  }}
                  onClick={() => handleBlueAreaClick(index)}
                />
              ))}
            </div>
          )}
        </div>
        <div>
          <Calendar />
          <Bookmark />
        </div>
      </div>

      {/* Conditional Modal */}
      <div className="modal fade" id="exampleModal" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {newsData.title}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-center">
              <img
                src={modalImg}
                alt="Selected"
                className="img-fluid"
                style={{ transform: `scale(${zoomLevel})` }}
              />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-success"
                onClick={handleZoomIn}
              >
                +
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={handleZoomOut}
              >
                -
              </button>
              <button
                type="button"
                class="btn btn-warning"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsDetails;
