import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import "./EditUploadedNewsContent.css";
import { updateNews } from "../../redux/admin/uploadSlice";

const EditUploadedNewsContent = () => {
  const { data: state } = useSelector((state) => state.upload);
  // console.log(state);
  const params = useParams();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("Select Category");
  const [selectedStatus, setSelectedStatus] = useState("Select Status");
  const [postDate, setPostDate] = useState("");
  const [addFormStatus, setAddFormStatus] = useState("Add Pages");
  const [pageData, setPageData] = useState([]);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Add Pages All Function
  const setFormStatusToAddPage = (str) => {
    setAddFormStatus(str);
    setPageData([...pageData, { page_name: "", file: "" }]);
  };

  const setFormStatusToClosePage = (str) => {
    setAddFormStatus(str);
    setPageData([]);
  };

  const addPage = () => {
    setPageData([...pageData, { page_name: "", file: "" }]);
    // console.log("end")
  };

  const handlePageChange = (e, ind, str) => {
    // console.log(e.target.value, ind);
    if (str === "name") {
      const newData = pageData.map((el, index) => {
        if (ind === index) {
          el.page_name = e.target.value;
        }
        return el;
      });
      setPageData([...newData]);
    } else {
      const newData = pageData.map((el, index) => {
        if (ind === index) {
          const file = e.target.files[0];
          if (
            (file && file.type === "image/jpeg") ||
            (file && file.type === "image/jpg") ||
            (file && file.type === "image/png")
          ) {
            el.file = file;
          }
        }
        return el;
      });
      setPageData([...newData]);
    }
    // console.log(pageData);
  };

  const handleRemove = (ind) => {
    const newData = pageData.map((el, index) => {
      if (ind === index) {
        el.file = "";
      }

      return el;
    });
    setPageData([...newData]);
    // console.log(pageData);
  };

  //function for send data to backend(handle Submit function)
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataObject = {
      title: title,
      description: description,
      category_id: selectedCategory,
      status: selectedStatus,
      post_date: postDate,
      attachment: pageData,
      page_name: [...pageData.map((el, ind) => el.page_name)],
    };
    // console.log(formDataObject);

    try {
      await dispatch(updateNews(formDataObject, state[params.ind].id));
    } catch (error) {
      return console.log(error);
    }
    //clear the form fields
    setTitle("");
    setDescription("");
    setSelectedCategory("Select Category");
    setSelectedStatus("Select Status");
    setPostDate("");
    setPageData([]);

    // navigate("/admin/listOfNews");
  };

  //function to get all categories
  const getCategories = async () => {
    try {
      const token = localStorage.getItem("axis_token");
      if (!token) {
        throw new Error("Authorization token is missing or invalid.");
      }
      const data = await axios.get(
        `${process.env.REACT_APP_APIURL}/categories`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(data.data.data)
      setCategories(data.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategories();
    return () => {
      console.log("component end");
    };
  }, []);

//set category value in select tag automatically
  // const setCategoriesValue = (arr, id) => {
  //   for(let i=0; i<arr.length; i++){
  //     console.log(arr[i])
  //   }
  // };
  // useEffect(() => {
  //   // setCategoriesValue(categories, state[params.ind].category_id)
  //   setTitle(state[params.ind].title);
  //   setDescription(state[params.ind].description);
  //   setSelectedStatus(state[params.ind].status);
  //   setPostDate(state[params.ind].post_date.split("-").reverse().join("-"));
  // }, []);

  return (
    <>
      <div id="editSection-mainContainer">
        <div className="edit-section-header">
          <h2>Edit News</h2>
          <Link to={"/admin/listOfNews"}>
            <button className="edit-section-back-btn">Back</button>
          </Link>
        </div>
        <div className="form-box">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label">Title</label>
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Description</label>
              <input
                type="text"
                className="form-control"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Category-ID</label>
              <select
                className="form-select"
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                <option value="">Select Category</option>
                {categories.map((el, ind) => (
                  <option key={ind} value={el.id}>
                    {el.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label className="form-label">Status</label>
              <select
                className="form-select"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="">Select Status</option>
                <option value="draft">Draft</option>
                <option value="published">Published</option>
              </select>
            </div>
            <div className="mb-3">
              <label className="form-label">Post Date</label>
              <input
                type="date"
                className="form-control"
                value={postDate}
                onChange={(e) => setPostDate(e.target.value)}
              />
            </div>

            {/* add pages section */}
            <div>
              {addFormStatus === "Add Pages" && (
                <button
                  className="add-page"
                  onClick={() => setFormStatusToAddPage("Close Form")}
                >
                  Add Pages
                </button>
              )}
              {addFormStatus === "Close Form" && (
                <button
                  className="add-page"
                  onClick={() => setFormStatusToClosePage("Add Pages")}
                >
                  Close Form
                </button>
              )}
              {addFormStatus === "Close Form" && (
                <div>
                  {pageData.map((el, ind) => (
                    <div key={ind} className="add-news-section">
                      <input
                        type="text"
                        placeholder="Page Name"
                        onChange={(e) => handlePageChange(e, ind, "name")}
                      />
                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        onChange={(e) => handlePageChange(e, ind, "file")}
                      />
                      <div className="remove-and-image-preview">
                        {el.file && (
                          <button
                            className="add-page"
                            onClick={() => handleRemove(ind)}
                          >
                            Remove
                          </button>
                        )}
                        {/* uploaded image preview section */}
                        {el.file && (
                          <div className="uploaded-image">
                            <img
                              src={URL.createObjectURL(el?.file)}
                              alt={`Uploaded ${ind + 1}`}
                              width={"100%"}
                              title={el.page_name}
                            />
                            {/* <p className="page-name">{el.page_name}</p> */}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                  {pageData.length > 0 && (
                    <button
                      type="button"
                      onClick={addPage}
                      className="add-page"
                    >
                      Add Next Page
                    </button>
                  )}
                </div>
              )}
            </div>

            {/* submit button */}
            <button className="btn btn-primary" id="submitBtn" type="submit">
              Save
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditUploadedNewsContent;
