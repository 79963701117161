import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const uploadSlice = createSlice({
  name: "upload",
  initialState: {
    data: [],
    isSuccess: false,
    isError: false,
    message: "",
  },
  reducers: {
    uploadRequest: (state) => {
      state.isSuccess = true;
      state.isError = false;
    },
    uploadSuccess: (state, action) => {
      state.isSuccess = true;
      state.isError = false;
      //   state.message = action.payload;
      state.data = action.payload;
      console.log(state.message, "message");
    },
    uploadFailure: (state, action) => {
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload;
    },
  },
});

export const { uploadRequest, uploadSuccess, uploadFailure } =
  uploadSlice.actions;
export default uploadSlice.reducer;

export const upload = (data) => async (dispatch, getState) => {
  dispatch(uploadRequest());

  try {
    // title, description, category_id, status, attachment[], post_date, page_name[]  -----> backend structure
    let files = [];
    for (let i = 0; i < data.attachment.length; i++) {
      files.push(data.attachment[i].file);
    }

    // Create a FormData object and append data to it
    let formData = new FormData();
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("category_id", data.category_id);
    formData.append("status", data.status);
    formData.append("post_date", data.post_date);
    files.forEach((page) => {
      formData.append("attachment[]", page);
    });
    data.page_name.forEach((page) => {
      formData.append("page_name[]", page);
    });
    // console.log(formData)

    // Get the token from localStorage
    const token = localStorage.getItem("axis_token");
    // Check if the token is present
    if (!token) {
      throw new Error("Authorization token is missing or invalid.");
    }
    const response = await axios.post(
      `${process.env.REACT_APP_APIURL}/news`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    // console.log(response.data);
    dispatch(uploadSuccess(response.data));
  } catch (error) {
    // Handle errors, log them, or dispatch failure action
    console.error("Error:", error);
    dispatch(uploadFailure(error));
  }
};

//fetch data
export const getUploadedNews = () => async (dispatch, getState) => {
  dispatch(uploadRequest());

  try {
    const token = localStorage.getItem("axis_token");
    if (!token) {
      throw new Error("Authorization token is missing or invalid.");
    }
    const data = await axios.get(`${process.env.REACT_APP_APIURL}/news`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    // console.log(data);
    dispatch(uploadSuccess(data.data.data));
  } catch (error) {
    console.error("Error:", error);
    dispatch(uploadFailure(error));
  }
};

//delete news by id
export const deleteNews = (id) => async (dispatch, getState) => {
  dispatch(uploadRequest());
  try {
    const token = localStorage.getItem("axis_token");
    if (!token) {
      throw new Error("Authorization token is missing or invalid.");
    }
    const res = await axios.delete(
      `${process.env.REACT_APP_APIURL}/news?id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    console.log(res);
  } catch (error) {
    console.error("Error:", error);
    dispatch(uploadFailure(error));
  }
};

// update news by id
export const updateNews = (data, id) => async (dispatch, getState) => {
  console.log(data);
  dispatch(uploadRequest());

  try {
    let files = [];
    for (let i = 0; i < data.attachment.length; i++) {
      files.push(data.attachment[i].file);
    }

    // Create a FormData object and append data to it
    let formData = new FormData();
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("category_id", data.category_id);
    formData.append("status", data.status);
    formData.append("post_date", data.post_date);
    files.forEach((page) => {
      formData.append("attachment[]", page);
    });
    data.attachment.forEach((page) => {
      formData.append("page_name[]", page.page_name);
    });
    formData.append("id", id);
    // console.log(formData)

    // Get the token from localStorage
    const token = localStorage.getItem("axis_token");
    // Check if the token is present
    if (!token) {
      throw new Error("Authorization token is missing or invalid.");
    }
    const response = await axios.put(
      `${process.env.REACT_APP_APIURL}/news`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    // console.log(response.data);
    dispatch(uploadSuccess(response.data));
  } catch (error) {
    // Handle errors, log them, or dispatch failure action
    console.error("Error:", error);
    dispatch(uploadFailure(error));
  }
};

// update news mapped_data by id
export const updateNewsMapping = (data, id) => async (dispatch, getState) => {
  console.log(data);
  dispatch(uploadRequest());

  try {
    // Get the token from localStorage
    const token = localStorage.getItem("axis_token");
    // Check if the token is present
    if (!token) {
      throw new Error("Authorization token is missing or invalid.");
    }
    const response = await axios.put(
      `${process.env.REACT_APP_APIURL}/news?id=${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    // console.log(response.data);
    dispatch(uploadSuccess(response.data));
  } catch (error) {
    // Handle errors, log them, or dispatch failure action
    console.error("Error:", error);
    dispatch(uploadFailure(error));
  }
};
