export const customers = [
    {
        name: "Samantha Melon",
        profilePicture: "https://www.spruko.com/demo/valex/Valex/assets/img/users/3.jpg",
        userId: 1234,
        status:"Paid"
    },
    {
        name: "Samantha Melon",
        profilePicture: "https://www.spruko.com/demo/valex/Valex/assets/img/users/3.jpg",
        userId: 1234,
        status:"Paid"
    },
    {
        name: "Samantha Melon",
        profilePicture: "https://www.spruko.com/demo/valex/Valex/assets/img/users/3.jpg",
        userId: 1234,
        status:"Paid"
    },
    {
        name: "Samantha Melon",
        profilePicture: "https://www.spruko.com/demo/valex/Valex/assets/img/users/3.jpg",
        userId: 1234,
        status:"Pending"
    },
    {
        name: "Samantha Melon",
        profilePicture: "https://www.spruko.com/demo/valex/Valex/assets/img/users/3.jpg",
        userId: 1234,
        status:"Pending"
    },
    {
        name: "Samantha Melon",
        profilePicture: "https://www.spruko.com/demo/valex/Valex/assets/img/users/3.jpg",
        userId: 1234,
        status:"Paid"
    },
]