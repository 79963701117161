import React from 'react'
import { Route,Routes} from 'react-router-dom'

import Navbar from '../app/Navbar';
import HomePage from '../app/HomePage';
import MultiStepReg from '../auth/MultistepReg';

function NavbarLink() {
  
    return (
      <>   
      <Navbar/>
          <div>
            <Routes>
              <Route exact path="/" element={<HomePage/>} />
              <Route path="/register" element={<MultiStepReg/>}/>
            </Routes>
          </div>
      </>
    );
  }

export default NavbarLink