import React from 'react';

import '../css/Editions.css'
import FrontPage from '../app/utils/front.png'
function Editions(){
    return(
        <div className='editionsCont'>
          <div>
            {/* <img src={FrontPage} /> */}
          </div>
          <div></div>
          <div></div>
          <div></div>
        </div>
    )
}

export default Editions