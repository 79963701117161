import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

export const SidebarData = [
{
	title: "Upload",
	path: "/admin/uploadSection",
	icon: <AiIcons.AiFillHome />,
	iconClosed: <RiIcons.RiArrowDownSFill />,
	iconOpened: <RiIcons.RiArrowUpSFill />,
},
{
	title: "Services",
	path: "/admin/services",
	icon: <IoIcons.IoIosPaper />,
	iconClosed: <RiIcons.RiArrowDownSFill />,
	iconOpened: <RiIcons.RiArrowUpSFill />,
},
{
	title: "Users",
	path: "/admin/users",
	icon: <FaIcons.FaPhone />,
},
{
	title: "List of uploaded News",
	path: "/admin/listOfNews",
	icon: <IoIcons.IoIosPaper />,
},
{
	title: "Add Category",
	path: "/admin/addCategory",
	icon: <IoIcons.IoIosPaper />,
},

 
];
