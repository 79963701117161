import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    data: [],
    isSuccess: false,
    isError: false,
    message: '',
  },
  reducers: {
    registerRequest: (state) => {
      state.isSuccess = true;
      state.isError = false;
    },
    registerSuccess: (state, action) => {
      state.isSuccess = true;
      state.isError = false;
      state.message = action.payload;
      console.log(state.message, 'message')
    },
    registerFailure: (state, action) => {
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload;
    },
  },
});

export const { registerRequest, registerSuccess, registerFailure } = authSlice.actions;

export const register = (payload) => async (dispatch) => {
  dispatch(registerRequest());

  try {
    const response = await axios.post('https://jugasankha.travwiki.com/apis/auth', payload);
    dispatch(registerSuccess(response.data));
  } catch (error) {
    dispatch(registerFailure(error));
  }
};


export default authSlice.reducer;
