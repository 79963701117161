import React, { useEffect, useState } from "react";
import "../css/UploadedNewsSection.css";
import ImageCropper from "./mapArea";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

const UploadedNewsSection = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const params = useParams();
  const [uploadedNews, setUploadedNews] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getUploadedNews = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("axis_token");
      if (!token) {
        throw new Error("Authorization token is missing or invalid.");
      }
      const data = await axios.get(
        `${process.env.REACT_APP_APIURL}/news?id=${params.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setUploadedNews(data.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUploadedNews();
    return () => {
      console.log("component end");
    };
  }, []);

  // Function to handle image click
  const handleImageClick = (image) => {
    setSelectedImage(image);
    console.log('selectedImage',selectedImage)
  };

  return (
    <>
      <div>
        <div className="header-part">
          <h4>Uploaded News</h4>
          <Link to={"/admin/listOfNews"}>
            <button className="back-btn">Back</button>
          </Link>
        </div>
        <div className="uploaded-news-main-container">
          <div className="left-side-box">
            {isLoading ? (
              <p>Loading...</p> // Loading indicator
            ) : uploadedNews &&
              uploadedNews.attachments &&
              uploadedNews.attachments.length > 0 ? (
              uploadedNews.attachments.map((el, index) => (
                <div key={index} onClick={() => handleImageClick(el)}>
                  <img width="100%" src={ process.env.REACT_APP_APIURL + "/uploads/news/" + el.filename } alt={`Uploaded ${index + 1}`} style={{ cursor: "pointer" }} />
                  <p>{el.page_name}</p>
                </div>
              ))
            ) : (
              <p>No Data Available</p>
            )}
          </div>

          <div className="right-side-box">
            {selectedImage && (
              <div>
                <ImageCropper key={selectedImage.id} width="100%" src={ process.env.REACT_APP_APIURL + "/uploads/news/" + selectedImage.filename } alt={selectedImage.id}/>
                <p style={{ textAlign: "center", margin: 0, marginTop: "1rem" }}> {selectedImage.page_name} </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadedNewsSection;
