import React from "react";

export const Services = () => {
return (
	<div className="services">
	<h1>Jugasankha Services</h1>
	</div>
);
};

