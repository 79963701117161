import React from 'react'

function Payment () {
    return (
        <div>
payment
        </div>
    )
}

export default Payment