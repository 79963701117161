import React from 'react'
import { FaFacebook, FaTwitter, FaLinkedin, FaGithub } from 'react-icons/fa';
import '../css/Footer.css'
import Logo from '../app/utils/logo.png'
import { Link } from 'react-router-dom';
function Footer() {
    return (
        <div>
           <footer class="footer-distributed">

<div class="footer-left">

    <img className='logo' src={Logo} alt='Jugasankha logo' />

    <p class="footer-links">
       <Link to='/about'>About Us</Link>
        <Link to='/contact'>Contact Us</Link>
        <Link to='/terms'>Terms & Conditions</Link>
       
    </p>

    <p class="footer-company-name">Copyright © www.jugasankhaepaper.com</p>
</div>

<div class="footer-center">

    <div>
        <i class="fa fa-map-marker"></i>
        <p><span>Karatigram, Rongpur
Silchar</span>  , Cachar , Assam
PIN :- 788009</p>
    </div>

    <div>
        <i class="fa fa-phone"></i>
        <p>+916901275944</p>
    </div>

    <div>
        <i class="fa fa-envelope"></i>
        <p><a href="mailto:support@company.com">contact@jugasankhaepaper.com</a></p>
    </div>

</div>

<div class="footer-right">

    <p class="footer-company-about">
        <span>About Jugasankha</span>
        Jugasankha is a media company offering Bengali e-paper and physical newspapers in Silchar, Guwahati, Kolkata, and Siliguri. 
    </p>

    <div className="footer-icons">
      <a href="#"><FaFacebook /></a>
      <a href="#"><FaTwitter /></a>
      <a href="#"><FaLinkedin /></a>
      <a href="#"><FaGithub /></a>
    </div>

</div>

</footer>
        </div>
    )
}

export default Footer
