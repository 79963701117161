import React, { useState } from 'react';
import '../css/MultiStepReg.css'; // 
import { FaCheck } from 'react-icons/fa'; 
import Register from '../auth/Register';
import OtpVerification from '../auth/OtpVerification';
import Payment from '../auth/Payment';

const steps = ['Register', 'OTP Verification', 'Payment'];

function MultiStepReg() {
  const [currentStep, setCurrentStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);

  const handleNext = () => {
    if (!completedSteps.includes(currentStep)) {
      setCompletedSteps([...completedSteps, currentStep]);
    }
    setCurrentStep(currentStep + 1);
    console.log(completedSteps,  currentStep)
  };

  return (
    <div className="multistepContainer">
      <div className='progressBox'>
      <div className="progressContainer">
        <div class={currentStep !==0?'circle active':'circle'}>{currentStep !==0?<FaCheck/>:1}</div>
        <div class="line"></div>
        <div class={currentStep===2?'circle active':'circle'}>{currentStep ===2?<FaCheck/>:2}</div>
        <div class="line"></div>
        <div class={currentStep ===4?'circle active':'circle'}>{currentStep ===4?<FaCheck/>:3}</div>
        </div>
        <div className='progressTitle'>
          <div>Create Accoount</div>
          <div>OTP Verfication</div>
          <div>Payment</div>
        </div>
      </div>
      
        
      {currentStep === 0 && <Register onNext={handleNext} />}
      {currentStep === 1 && <OtpVerification onNext={handleNext} />}
      {currentStep === 2 && <Payment />}
    </div>
  );
}

export default MultiStepReg;
 