import React from 'react'

function OtpVerification ({onNext}){
    return (
        <div>
            Otp Verification
            <button onClick={onNext}>Next</button>
        </div>
    )
}

export default OtpVerification